import { useState } from "react";
import { data } from "../../data";

const FilterButtons = ({ filterType, resetFilter, selectedCategory }) => {
  return (
    <div className="flex flex-wrap justify-center gap-4 mb-6">
      <button
        onClick={resetFilter}
        className={`px-4 py-2 ${
          selectedCategory === "all"
            ? "bg-red-600 text-white"
            : "border-2 border-red-600 text-red-600 hover:bg-red-600 hover:text-white"
        } rounded-lg shadow-md transition duration-300 ease-in-out`}
      >
        All
      </button>
      {[
        { label: "Sandwiches", category: "sandwich" },
        { label: "Eggs", category: "egg" },
        { label: "Waffle, Cakes, & French Toast", category: "waffle" },
        { label: "Sandwiches & Burritos", category: "burritos" },
        { label: "Healthy Choices", category: "healthy" },
        { label: "Omelets", category: "omellete" },
        { label: "Kids'", category: "kid" },
        { label: "Sides", category: "side" },
        { label: "Starters", category: "starter" },
        { label: "Salads", category: "salad" },
        { label: "Sweets", category: "sweet" },
        { label: "Drinks", category: "drink" },
        { label: "Dinners", category: "dinner" },
        { label: "Soups", category: "soup" },
        { label: "Burgers & Wraps", category: "burger" },
      ].map((button) => (
        <button
          key={button.category}
          onClick={() => filterType(button.category)}
          className={`px-4 py-2 ${
            selectedCategory === button.category
              ? "bg-red-600 text-white"
              : "border-2 border-red-600 text-red-600 hover:bg-red-600 hover:text-white"
          } rounded-lg shadow-md transition duration-300 ease-in-out`}
        >
          {button.label}
        </button>
      ))}
    </div>
  );
};

const Menu = () => {
  const [foods, setFoods] = useState(data);
  const [selectedCategory, setSelectedCategory] = useState("all");

  const filterType = (category) => {
    setFoods(data.filter((item) => item.category === category));
    setSelectedCategory(category);
  };

  const resetFilter = () => {
    setFoods(data);
    setSelectedCategory("all");
  };

  return (
    <div className="max-w-[1640px] m-auto px-4 py-12" id="menu">
      <h1 className="text-red-700 font-bold text-5xl text-center mt-8 app__header-h1">
        Menu
      </h1>

      <FilterButtons
        filterType={filterType}
        resetFilter={resetFilter}
        selectedCategory={selectedCategory}
      />

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 pt-8">
        {foods.map((item, index) => (
          <div
            key={index}
            className="border shadow-lg rounded-lg hover:scale-105 duration-300 bg-white"
          >
            <img
              src={item.image}
              alt={item.name}
              className="w-full h-[200px] object-cover rounded-t-lg"
            />
            <div className="flex justify-between px-4 py-4 bg-gray-100">
              <p className="font-bold text-lg">{item.name}</p>
              <p>
                <span className="bg-red-600 text-white p-2 rounded-lg">
                  {item.price}
                </span>
              </p>
            </div>
            <p className="px-4 py-2 text-sm text-gray-600">
              ❗Note: {item.note || "No Note Needed"}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Menu;
