import { SubHeading } from "../../components";
import "./Header.css";

const Header = () => (
  <div
    className="app__header app__wrapper section__padding"
    id="home"
  >
    <div className="app__wrapper_info">
      <SubHeading title="Rediscover Timeless Flavors at The Old Serpico Diner" />
      <h1 className="app__header-h1">Welcome to The Old Serpico Diner</h1>
      <p
        className="p__opensans"
        style={{ margin: "2rem 0" }}
      >
        Step into the heart of nostalgia with a touch of Italian flair at The
        Old Serpico Diner. As you enter, you'll be greeted not only by the warm,
        vintage atmosphere but also by the spirit of authentic Italian
        hospitality. Our menu reflects our heritage, offering a delightful
        fusion of classic diner favorites and traditional Italian flavors. From
        hearty pasta dishes to timeless American comfort food, each bite is a
        testament to our commitment to quality and taste. Join us in embracing
        the perfect blend of old-world charm and Italian culinary excellence,
        where every meal is a celebration of tradition and the joy of good food.
        Benvenuti! (Welcome!)
      </p>
      <button
        type="button"
        className="custom__button"
        onClick={() => {
          const mainDiv = document.getElementById("menu");
          mainDiv.scrollIntoView({ behavior: "smooth" });
        }}
      >
        Explore Menu
      </button>
    </div>
  </div>
);

export default Header;
