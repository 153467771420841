import Swal from "sweetalert2";

const close_alert = () => {
  let currentDate = new Date();
  let closedDateone = new Date("07/04/2024");
  let closedDatetwo = new Date("07/05/2024");

  if (
    currentDate.toDateString() === closedDateone.toDateString() ||
    currentDate.toDateString() === closedDatetwo.toDateString()
  ) {
    Swal.fire({
      title: "Closed!",
      html: "The Old Serpico Diner will be closed on July 4th and 5th. We will reopen on July 6th. Have a wonderful holiday!",
      icon: "info",
      allowOutsideClick: true,
      confirmButtonText: "Close",
    });
  }
};

export default close_alert;
