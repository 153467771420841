import { Information, Footer, Header, Menu, Alerts } from "./container";
import { Analytics } from "@vercel/analytics/react";
import { Navbar } from "./components";
import "./App.css";

const App = () => (
  <div>
    <Alerts />
    <Navbar />
    <Header />
    <Menu />
    <Information />
    <Footer />
    <Analytics />
  </div>
);

export default App;
