export const data = [
  {
    id: 1,
    name: "Lunch Roast Beef with Mashed",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Served with fries.",
  },
  {
    id: 2,
    name: "Lunch Loaded Philly Sub",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Served with fries.",
  },
  {
    id: 3,
    name: "Lunch Grilled Cheese",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$6.70",
    note: "Served with fries.",
  },
  {
    id: 4,
    name: "Lunch Club",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
  },
  {
    id: 5,
    name: "Lunch Fried Fish Sub",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
  },
  {
    id: 6,
    name: "Lunch Hot Turkey with Mashed",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Served with fries.",
  },
  {
    id: 7,
    name: "Lunch BLT Sandwich",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$6.70",
  },
  {
    id: 8,
    name: "Lunch Deli Sandwich",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$6.70",
    note: "Roast beef, turkey, ham, or corn beef with mac or potato salad.",
  },
  {
    id: 9,
    name: "Lunch Egg Salad",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$6.70",
    note: "With mac or potato salad.",
  },
  {
    id: 10,
    name: "Lunch Reuben",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Served with fries.",
  },
  {
    id: 11,
    name: "Lunch Grilled Ham & Cheese",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$7.70",
  },
  {
    id: 12,
    name: "Lunch Meatball Sub",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Served with fries.",
  },
  {
    id: 13,
    name: "Lunch Tuna Melt",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$8.70",
    note: "Served with fries.",
  },
  {
    id: 14,
    name: "Lunch Tuna Salad",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$6.70",
    note: "With mac or potato salad.",
  },
  {
    id: 15,
    name: "Lunch Italian Sausage Sub",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Served with fries.",
  },
  {
    id: 16,
    name: "Dinner Roast Beef With Mashed",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "",
  },
  {
    id: 17,
    name: "Dinner BLT Sandwich",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$6.70",
    note: "Served with fries.",
  },
  {
    id: 18,
    name: "Dinner Hot Turkey With Mashed",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "",
  },
  {
    id: 19,
    name: "Dinner Italian Sausage Sub",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Served with fries.",
  },
  {
    id: 20,
    name: "Dinner Loaded Philly Sub",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "",
  },
  {
    id: 21,
    name: "Dinner Club",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "",
  },
  {
    id: 22,
    name: "Dinner Deli Sandwich",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$7.70",
    note: "Roast beef, turkey, ham, or corn beef with mac or potato salad.",
  },
  {
    id: 23,
    name: "Dinner Tuna Melt",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$8.70",
    note: "",
  },
  {
    id: 24,
    name: "Dinner Grilled Cheese",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$6.70",
    note: "Served with fries.",
  },
  {
    id: 25,
    name: "Dinner Tuna Salad",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$6.70",
    note: "",
  },
  {
    id: 26,
    name: "Dinner Grilled Ham & Cheese",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$7.70",
    note: "",
  },
  {
    id: 27,
    name: "Dinner Egg Salad",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$6.70",
    note: "With mac or potato salad.",
  },
  {
    id: 28,
    name: "Dinner Reuben",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "",
  },
  {
    id: 29,
    name: "Dinner Meatball Sub",
    category: "sandwich",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Served with fries.",
  },
  {
    id: 30,
    name: "Eggs (2), NY Strip Steak, Home Fries & Toast",
    category: "egg",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$11.70",
    note: "",
  },
  {
    id: 31,
    name: "Eggs (2), Home Fries & Toast",
    category: "egg",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$3.95",
    note: "",
  },
  {
    id: 32,
    name: "Eggs (2), Meat & Toast",
    category: "egg",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$6.70",
    note: "",
  },
  {
    id: 33,
    name: "Eggs (2), Fried Haddock, Grits & Toast",
    category: "egg",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$12.70",
    note: "",
  },
  {
    id: 34,
    name: "Eggs (2), Country Fried Streak, Home Fries & Toast",
    category: "egg",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$12.70",
    note: "",
  },
  {
    id: 35,
    name: "Eggs (2), Country Fried Steak, Home Fries & Toast",
    category: "egg",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "",
  },
  {
    id: 36,
    name: "Eggs (2), Corn Beef Hash & Toast",
    category: "egg",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$7.70",
    note: "",
  },
  {
    id: 37,
    name: "Eggs (2), Salmon Patty, Gritts & Toast",
    category: "egg",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$11.70",
    note: "",
  },
  {
    id: 38,
    name: "Eggs (2) & Toast",
    category: "egg",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$3.70",
    note: "",
  },
  {
    id: 39,
    name: "Peppers with Scrambled Eggs & Toast",
    category: "egg",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$5.75",
    note: "",
  },
  {
    id: 40,
    name: "Eggs (2) with Sausage, Biscuit & Gravy",
    category: "egg",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$8.70",
    note: "",
  },
  {
    id: 41,
    name: "Eggs (2), Hash Browns & Toast",
    category: "egg",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$7.70",
    note: "",
  },
  {
    id: 42,
    name: "Broccoli with Scrambled Eggs & Toast",
    category: "egg",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$5.75",
    note: "",
  },
  {
    id: 43,
    name: "Slam",
    category: "waffle",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$8.70",
    note: "Two slices of french toast or two pancakes with two eggs and choice of meat",
  },
  {
    id: 44,
    name: "Pancake(s)",
    category: "waffle",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$2.75",
    note: "",
  },
  {
    id: 45,
    name: "French Toast (3)",
    category: "waffle",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$4.70",
    note: "Comes with Three Slices",
  },
  {
    id: 46,
    name: "Waffle(s)",
    category: "waffle",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$6.75",
    note: "",
  },
  {
    id: 47,
    name: "Waffle with Fruit",
    category: "waffle",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$8.70",
    note: "",
  },
  {
    id: 48,
    name: "Turtle Waffle",
    category: "waffle",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$8.70",
    note: "Waffle with caramel, chocolate sauce, and nuts.",
  },
  {
    id: 49,
    name: "Breakfast Sandwich",
    category: "burritos",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$5.25",
    note: "Egg, cheese, and choice of meat on a bagel.",
  },
  {
    id: 50,
    name: "Breakfast Burrito",
    category: "burritos",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$6.70",
    note: "Veggie, Italian, western, or choice served with home fries.",
  },
  {
    id: 51,
    name: "Western Sandwich",
    category: "burritos",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$4.75",
    note: "Ham, pepper, onions, and eggs on toast.",
  },
  {
    id: 52,
    name: "Bowl Oatmeal",
    category: "healthy",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$4.75",
    note: "",
  },
  {
    id: 53,
    name: "Egg Beaters with Toast",
    category: "healthy",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$5.75",
    note: "",
  },
  {
    id: 54,
    name: "Egg White Omelet with 3 Items",
    category: "healthy",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Served with a cup of fruit or yogurt.",
  },
  {
    id: 55,
    name: "Healthy Slam",
    category: "healthy",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Two slices of wheat french toast with cup of fruit and yogurt.",
  },
  {
    id: 56,
    name: "Build Your Own Omellete",
    category: "omellette",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Served with home fries and toast.",
  },
  {
    id: 57,
    name: "Greek Omelette",
    category: "omellette",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Spinach, feta, and tomato.",
  },
  {
    id: 58,
    name: "Meatlover's Omelette",
    category: "omellette",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Pepperoni, ham, sausage, and cheese.",
  },
  {
    id: 59,
    name: "Western Omelette",
    category: "omellette",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Ham, peppers, and onions.",
  },
  {
    id: 60,
    name: "Italian Omelette",
    category: "omellette",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Sausage, cheese, peppers, and onions.",
  },
  {
    id: 61,
    name: "Pizza Omelette",
    category: "omellette",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Sausage, onions, peppers, mushrooms, pepperoni, and provolone cheese.",
  },
  {
    id: 62,
    name: "Fretta Omelette",
    category: "omellette",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$8.70",
    note: "Sausage, peppers, onions, mushroomsm, broccoli, pepperoni, and home fries and egg.",
  },
  {
    id: 63,
    name: "Philly Steak Omelette",
    category: "omellette",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Steak, pepper, onions and cheese",
  },
  {
    id: 64,
    name: "Italian Fretta Omelette",
    category: "omellete",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$8.45",
    note: "Fretta with provolone cheese and sauce.",
  },
  {
    id: 65,
    name: "Kid Pancakes (3)",
    category: "kid",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$3.70",
    note: "Comes with Three Pancakes",
  },
  {
    id: 66,
    name: "Kids Egg (1) & Slice of Toast (1)",
    category: "kid",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$2.75",
    note: "Comes with One Egg and One Slice of Toast",
  },
  {
    id: 67,
    name: "Kids French Toast (2)",
    category: "kid",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$3.70",
    note: "Comes with Two Slices of French Toast",
  },
  {
    id: 68,
    name: "Kids Add Meat",
    category: "kid",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$1.75",
    note: "Bacon or Sausage",
  },
  {
    id: 69,
    name: "Corn Beef Hash",
    category: "side",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$4.75",
    note: "",
  },
  {
    id: 70,
    name: "Home Fries",
    category: "side",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$2.75",
    note: "",
  },
  {
    id: 71,
    name: "Bacon",
    category: "side",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$3.75",
    note: "",
  },
  {
    id: 72,
    name: "Grits with Cheese",
    category: "side",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$4.25",
    note: "",
  },
  {
    id: 73,
    name: "Bowl of Fruit",
    category: "side",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$5.75",
  },
  {
    id: 74,
    name: "Home Fries with Onions & Peppers",
    category: "side",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$3.75",
    note: "",
  },
  {
    id: 75,
    name: "Sausage",
    category: "side",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$3.75",
    note: "",
  },
  {
    id: 76,
    name: "Toast",
    category: "side",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$2.75",
    note: "",
  },
  {
    id: 77,
    name: "Bagel",
    category: "side",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$2.75",
    note: "",
  },
  {
    id: 78,
    name: "Turkey Bacon",
    category: "side",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$4.75",
    note: "",
  },
  {
    id: 79,
    name: "Italian Sausage",
    category: "side",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$3.75",
    note: "",
  },
  {
    id: 80,
    name: "Home Fries with Onions",
    category: "side",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$3.25",
    note: "",
  },
  {
    id: 81,
    name: "Ham",
    category: "side",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$3.75",
    note: "",
  },
  {
    id: 82,
    name: "Hash Browns",
    category: "side",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$4.75",
    note: "",
  },
  {
    id: 83,
    name: "Patty",
    category: "side",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$4.75",
    note: "",
  },
  {
    id: 84,
    name: "Grits",
    category: "side",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$3.75",
    note: "",
  },
  {
    id: 85,
    name: "English Muffin",
    category: "side",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$2.75",
    note: "",
  },
  {
    id: 86,
    name: "Turkey Sausage",
    category: "side",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$4.75",
    note: "",
  },
  {
    id: 87,
    name: "Chicken Tenders",
    category: "starter",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$6.70",
    note: "",
  },
  {
    id: 88,
    name: "Cheese Bread",
    category: "starter",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$4.25",
  },
  {
    id: 89,
    name: "Cheese Sticks",
    category: "starter",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$6.75",
    note: "",
  },
  {
    id: 90,
    name: "Sampler Platter",
    category: "starter",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Cheese Sticks, Chicken Tenders, and Onion Rings",
  },
  {
    id: 91,
    name: "Chef Salad",
    category: "salad",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$5.75",
    note: "Served with fries.",
  },
  {
    id: 92,
    name: "Antipasto Salad",
    category: "salad",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "",
  },
  {
    id: 93,
    name: "Chicken Caesar Salad",
    category: "salad",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
  },
  {
    id: 94,
    name: "Chicken Club Salad",
    category: "salad",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Served with fries.",
  },
  {
    id: 95,
    name: "Caeasar Salad",
    category: "salad",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$6.70",
  },
  {
    id: 96,
    name: "Juleinne Salad",
    category: "salad",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "",
  },
  {
    id: 97,
    name: "Pie",
    category: "sweet",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$4.75",
    note: "",
  },
  {
    id: 98,
    name: "Cheesecake",
    category: "sweet",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$4.75",
    note: "",
  },
  {
    id: 99,
    name: "Rice Pudding",
    category: "sweet",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$3.75",
  },
  {
    id: 100,
    name: "Cake",
    category: "sweet",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$4.75",
    note: "",
  },
  {
    id: 101,
    name: "Juice",
    category: "drink",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$2.75",
    note: "Orange Juice or Apple Juice or Cranberry Juice or Grape Juice",
  },
  {
    id: 102,
    name: "Bottle Soda",
    category: "drink",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$2.75",
    note: "Pepsi or Diet Pepsi",
  },
  {
    id: 103,
    name: "Hot Chocolate",
    category: "drink",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$2.75",
    note: "",
  },
  {
    id: 104,
    name: "Fountain Soda",
    category: "drink",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$2.75",
    note: "Pepsi or Diet Pepsi",
  },
  {
    id: 105,
    name: "Coffee",
    category: "drink",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$2.75",
    note: "",
  },
  {
    id: 106,
    name: "Milk",
    category: "drink",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$2.75",
    note: "",
  },
  {
    id: 107,
    name: "Tea",
    category: "drink",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$2.75",
    note: "",
  },
  {
    id: 108,
    name: "Bottle Water",
    category: "drink",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$2.75",
    note: "",
  },
  {
    id: 109,
    name: "Mac Salad",
    category: "side",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$3.75",
    note: "",
  },
  {
    id: 110,
    name: "Home Fries with Gravy",
    category: "side",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$4.75",
    note: "",
  },
  {
    id: 111,
    name: "Potato Salad",
    category: "side",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$3.25",
    note: "",
  },
  {
    id: 112,
    name: "Onion Rings",
    category: "side",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$5.70",
    note: "",
  },
  {
    id: 113,
    name: "Apple Sauce",
    category: "side",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$3.25",
    note: "",
  },
  {
    id: 114,
    name: "Chicken Tenders & Fries",
    category: "dinner",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$8.70",
    note: "",
  },
  {
    id: 115,
    name: "Spaghetti & Meatballs",
    category: "dinner",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "With salad.",
  },
  {
    id: 116,
    name: "Spaghetti & Sausage",
    category: "dinner",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "With salad.",
  },
  {
    id: 117,
    name: "NY Strip Steak",
    category: "dinner",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$13.70",
    note: "With salad and fries.",
  },
  {
    id: 118,
    name: "Fish Dinner",
    category: "dinner",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$13.70",
    note: "With salad and coleslaw.",
  },
  {
    id: 119,
    name: "Liver & Onions",
    category: "dinner",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$10.70",
    note: "With salad and mashed potatoes.",
  },
  {
    id: 120,
    name: "Bowl Soup",
    category: "soup",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$5.75",
    note: "",
  },
  {
    id: 121,
    name: "Bowl Chili with Cheddar",
    category: "soup",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$7.70",
    note: "",
  },
  {
    id: 122,
    name: "Cup Soup",
    category: "soup",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$3.75",
    note: "",
  },
  {
    id: 123,
    name: "Bacon Cheeseburger",
    category: "burger",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Served with fries.",
  },
  {
    id: 124,
    name: "Mushroom & Swiss Burger",
    category: "burger",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Served with fries.",
  },
  {
    id: 125,
    name: "Steak Fajita Wrap",
    category: "burger",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Served with fries.",
  },
  {
    id: 126,
    name: "Burger",
    category: "burger",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$6.75",
    note: "Served with fries.",
  },
  {
    id: 127,
    name: "Cheeseburger",
    category: "burger",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$6.75",
    note: "Served with fries.",
  },
  {
    id: 128,
    name: "Chicken Club Wrap",
    category: "burger",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Served with fries.",
  },
  {
    id: 129,
    name: "Chicken Caesar Wrap",
    category: "burger",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Served with fries.",
  },
  {
    id: 130,
    name: "Chicken Fajita Wrap",
    category: "burger",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Served with fries.",
  },
  {
    id: 131,
    name: "Grilled Chicken Wrap",
    category: "burger",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Served with fries.",
  },
  {
    id: 132,
    name: "State Fair Burger",
    category: "burger",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Served with fries. Peppers, onions, and cheese",
  },
  {
    id: 133,
    name: "Turkey Burger",
    category: "burger",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Served with fries.",
  },
  {
    id: 134,
    name: "Kansas City Burger",
    category: "burger",
    image:
      "https://eckerd.org/wp-content/uploads/2022/01/image-coming-soon.jpg",
    price: "$9.70",
    note: "Served with fries. BBQ sauce, bacon, and cheese on grilled Italian bread.",
  },
];

const quotes = [
  {
    quote:
      "Good old fashioned diner food. It was perfectly cooked and delicious!!!",
  },
  {
    quote:
      "Great food even when ordering it from home still hot and fresh best waffles!",
  },
  {
    quote:
      "Did not know this place existed but it was absolutely FIREEEEE and it came so quickly.",
  },
  {
    quote: "The pricing here is phenomenal!",
  },
  {
    quote: "They have the BEST Pancake. Nowhere else can top them.",
  },
  {
    quote: "always great, 10/10",
  },
  {
    qoute: "always good food and portions",
  },
];

export function getRandomQuote() {
  const randomIndex = Math.floor(Math.random() * quotes.length);
  return quotes[randomIndex].quote;
}
