import { SubHeading } from "../../components";

const Information = () => (
  <div
    className="app__bg app__wrapper section__padding"
    id="information"
  >
    <div className="app__wrapper_info">
      <SubHeading title="Information" />
      <h1
        className="headtext__cormorant"
        style={{ marginBottom: "2rem", color: "#bc1c35" }}
      >
        Find Us
      </h1>
      <div className="app__wrapper-content">
        <p className="p__opensans">
          <a
            href="https://www.google.com/maps?sca_upv=1&um=1&fb=1&gl=us&geocode=KfMgFKV18dmJMQuhyDovnc4X&daddr=512+State+Fair+Blvd%2C+Syracuse%2C+NY+13204"
            target="_blank"
            rel="noopener noreferrer"
          >
            We are located at 512 State Fair Blvd, Syracuse, NY 13204!
          </a>
        </p>
        <p
          className="headtext__cormorant"
          style={{ color: "#bc1c35", margin: "2rem 0" }}
        >
          Hours
        </p>
        <p className="p__opensans">Monday - Sunday: 5:30 AM - 2:00 PM</p>
        <p />
        <p
          className="p__opensans"
          style={{ textDecoration: "underline" }}
        >
          Please note that our diner hours are subject to change. We appreciate
          your understanding and encourage you to check our website or contact
          us for the latest updates on our operating hours.
        </p>
      </div>
      <button
        className="custom__button"
        style={{ marginTop: "2rem" }}
        onClick={() =>
          window.open(
            "https://www.google.com/maps?um=1&fb=1&gl=us&geocode=KfMgFKV18dmJMQuhyDovnc4X&daddr=512+State+Fair+Blvd%2C+Syracuse%2C+NY+13204"
          )
        }
      >
        Directions
      </button>
    </div>
  </div>
);

export default Information;
