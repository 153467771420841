import { getRandomQuote } from "../../data";
import "./Footer.css";

const currentYear = new Date().getFullYear();

const quote = getRandomQuote();

const Footer = () => (
  <div className="app__footer section__padding">
    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="app__footer-address">
          512 State Fair Blvd, Syracuse, NY 13204
        </p>
        <p className="app__footer-number">
          <a href="tel:3159374556" className="app__footer-number-link">
            315-937-4556
          </a>
        </p>
      </div>
      <div className="app__footer-links_quotes">
        <h1 className="app__footer-headtext">Customer Quotes</h1>
        <p className="app__footer-quote">&quot;{quote}&quot;</p>
      </div>
    </div>
    <div className="app__footer-copyright">
      <p className="app__footer-copyright-text">
        &copy; {currentYear} {""}
        <a
          href="https://www.theoldserpico.com/"
          className="app__footer-copyright-link"
        >
          {" "}
          The Old Serpico.
        </a>{" "}
        All Rights Reserved.
      </p>
      <p className="app__footer-copyright-developer">
        Developed by{" "}
        <a
          href="https://www.josephcarmosino.com"
          className="app__footer-developer-link"
        >
          Joseph Carmosino
        </a>
      </p>
    </div>
  </div>
);

export default Footer;
